import _module from 'module.js'
import jrnParser from 'classes/jrnParser'
/**
 * @ngdoc service
 * @name platformApp.pbxUtil
 * @description
 * # pbxUtil
 * Service in the platformApp.
 */

let deferreds = []
let pbxInfo = null
let requested = false
let tzRequested = false
let tzInfo = null
let tzDeferreds = []

const CALL_REPORTS_SYSTEM_BOARD_ID = '41f5a8e2-5cae-4ea4-a7ec-ca63f5264cdf'

const finalizePromises = () => {
  for (let i = 0, l = deferreds.length; i < l; i++) {
    deferreds[i].resolve(pbxInfo)
  }
}

const pbxInfoCallback = info => {
  pbxInfo = info
  finalizePromises()
}

const finalizeTimeZonePromises = () => {
  for (let i = 0, l = tzDeferreds.length; i < l; i++) {
    tzDeferreds[i].resolve(tzInfo)
  }
}

const timeZoneCallback = info => {
  tzInfo = info
  finalizeTimeZonePromises()
}

let _state, _globalState, _q, _window, _portalApi, _rootScope, _location, _jrn, _localStorage, _unleashService

export default class PbxUtil {
  constructor () {
    'ngInject'
    _rootScope.$on('pbxChanged', () => {
      requested = false
      pbxInfo = null
    })

    _rootScope.$on('pbxUpdated', () => {
      requested = false
      pbxInfo = null
    })
  }

  getPbxInfo () {
    if (!requested) {
      _portalApi.pbx
        .pbxs(_globalState.selectedPbxUUID)
        .get({ cache: true })
        .then(pbxInfoCallback)
      requested = true
    }

    let deferred = _q.defer()
    if (pbxInfo) {
      deferred.resolve(pbxInfo)
    } else {
      deferreds.push(deferred)
    }
    return deferred.promise
  }

  getExtensionLength () {
    return this.getPbxInfo().then(pbxData => {
      return pbxData.extensionDigits
    })
  }

  getValidTimeZones () {
    if (!tzRequested) {
      _portalApi.timeZone.get({ cache: true }).then(timeZoneCallback)
      tzRequested = true
    }

    let deferred = _q.defer()
    if (tzInfo) {
      deferred.resolve(tzInfo)
    } else {
      tzDeferreds.push(deferred)
    }
    return deferred.promise
  }

  goToUserHome () {
    let env = ''
    if (is.include(_location.host(), 'staging')) {
      env = 'staging.'
    } else if (is.include(_location.host(), 'dev')) {
      env = 'dev.'
    }

    if ( _location.host().indexOf('hvoice.net') < 0 ) {
      _window.open('https://my.' + env + 'jive.com/me')
    } else {
      _window.open('http://my.' + env + 'hvoice.net/me')
    }
  }

  goToLegacyCallReports (pbxDomain, locationHost) {
    let env = ''
    if (is.include(locationHost, 'staging')) {
      env = 'staging.'
    } else if (is.include(locationHost, 'dev')) {
      env = 'dev.'
    }

    if ( locationHost.indexOf('hvoice.net') < 0 ) {
      _window.open('https://my.' + env + 'jive.com/cr/' + pbxDomain)
    } else {
      _window.open('http://my.' + env + 'hvoice.net/cr/' + pbxDomain)
    }
  }

  goToUnifiedCallReports(pbxDomain, locationHost){
    let env = ''
    if (is.include(locationHost, 'staging')) {
      env = 'staging.gtc.'
    } else if (is.include(locationHost, 'dev') || is.include(locationHost, 'v5-admin-portal.ed.gtc.goto.com') || is.include(locationHost, 'localhost')) {
      env = 'dev.gtc.'
    }

    _window.open(`https://app.${env}goto.com/analytics/${pbxDomain}/dashboard/${CALL_REPORTS_SYSTEM_BOARD_ID}`)
  }

  goToCallReports (pbx) {
    if( _unleashService.isEnabled('calls-report-redirect')){
      this.goToUnifiedCallReports(pbx.domain, _location.host())
    }else {
      this.goToLegacyCallReports(pbx.domain, _location.host())
    }
  }

  // no support for hvoice yet
  goToAnalytics (pbx) {
    _window.open('https://app.goto.com/analytics/' + _jrn.parse(pbx.jrn).getAccount())
  }

  // no support for hvoice yet
  goToReleaseNotes () {
    _window.open('http://link.gotoconnect.com/whats-new')
  }

  goToContactCenterMarketing () {
    const params = new URLSearchParams();
    params.append('utm_source', 'product')
    params.append('utm_medium', 'ipd')
    params.append('utm_campaign', 'ccaas_default')
    _window.open(`https://www.goto.com/connect/contact-center-as-a-service/contact-sales?${params.toString()}`)
  }

  goToAlertCenter (pbx) {
    let env = ''
    if (
      is.include(_location.host(), 'staging') ||
      is.include(_location.host(), 'dev')
    ) {
      env = 'staging.'
    }
    _window.open(
      'http://my.' + env + 'jive.com/alerts/' + _jrn.parse(pbx.jrn).getAccount()
    )
  }

  goToPbx (id) {
    _state.go('root.nav.pbx.dashboard', {
      id: id
    })
  }

  redirectForUnifiedAdminPbx(pbx) {
    if (_globalState.isPlatform || _globalState.canManagePbxThroughPartner) {
      return false
    }

    if (pbx.unifiedAdmin) {
      return this.inPlaceRedirectToGoToAdmin(pbx)
    }
    return false
  }

  redirectForBossPartnerPbx(pbx) {
    // Todo: remove once we have full feature parity between GoTo Admin and Legacy Admin
    if (_globalState.isPlatform) {
      return false
    }

    if (pbx.partnerInfo && 'BOSS' === pbx.partnerInfo.partnerType) {
      return this.inPlaceRedirectToGoToAdmin(pbx)
    }
    return false
  }

  redirectForWeightedLicensePbx(pbx) {
    if (pbx.weightedLicense) {
      return this.inPlaceRedirectToGoToAdmin(pbx)
    }
    return false
  }

  getGoToAdminUrl(pbx) {
    let goToAdminUrl = 'https://admin.goto.com/'
      switch (window.environment) {
        case 'local':
        case 'dev':
        case 'feature':
          goToAdminUrl = 'https://admin.dev.gtc.goto.com/'
          break
        case 'staging':
          goToAdminUrl = 'https://admin.staging.gtc.goto.com/'
          break
      }
    return goToAdminUrl + pbx.lmiAccountKey
  }

  inPlaceRedirectToGoToAdmin(pbx) {
    // Clear last pbx in local storage because
    // we do not want to block access to the old
    // portal. See navBarController.js, determinePbxId.
    _localStorage.lastPbx = "";
    _window.location.href = this.getGoToAdminUrl(pbx);
    return true;
  }

  redirectToGoToAdmin(pbx) {
    _window.open(this.getGoToAdminUrl(pbx));
  }

  goToGtcBilling (pbx) {
    let gotoAdminUrl = this.getGoToAdminUrl(pbx);

    _window.open(gotoAdminUrl + '/gtc-billing');

  }

  static factory (
    $state,
    globalState,
    $q,
    $window,
    portalApi,
    $rootScope,
    $location,
    $localStorage,
    unleashService
  ) {
    'ngInject'
    _state = $state
    _globalState = globalState
    _q = $q
    _window = $window
    _portalApi = portalApi
    _rootScope = $rootScope
    _jrn = jrnParser
    _location = $location
    _localStorage = $localStorage
    _unleashService = unleashService

    return new PbxUtil()
  }
}

_module.factory('pbxUtil', PbxUtil.factory)
